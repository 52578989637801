exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-reading-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/content/reading.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-content-reading-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-posts-example-post-1-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/example-post-1.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-posts-example-post-1-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-posts-post-2-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/post-2.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-posts-post-2-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-posts-post-3-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/post-3.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-src-posts-post-3-mdx" */),
  "component---src-pages-reading-js": () => import("./../../../src/pages/reading.js" /* webpackChunkName: "component---src-pages-reading-js" */)
}

